import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import type { z } from 'zod';

import { OTP_CODE_LENGTH_6 } from '@dock/common';
import { useLocalStorage } from '@dock/react-hooks';
import { emailOtpCodeSchema } from '@dock/validation';

import { EmailConfirmationHeading, EmailConfirmationFormContent } from '../../components';
import { ErrorAlertWrapper } from '../../components/ErrorAlertWrapper/ErrorAlertWrapper';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { LocalStorageKeys } from '../../constants';
import { useEmailContext, useGlobalContext } from '../../hooks';
import { useVerifyEmailCallback } from '../../services';
import { ConfirmEmailFieldsEnum } from './constants/enums';

const NUMBER_TO_ADD_FOR_NEXT_STEP = 1;

export type EmailConfirmationFormValues = z.infer<typeof emailOtpCodeSchema>;

const defaultValues: EmailConfirmationFormValues = {
    [ConfirmEmailFieldsEnum.OTP_CODE]: '',
};

export function EmailConfirmation() {
    const { email } = useEmailContext();

    const methods = useForm<EmailConfirmationFormValues>({
        defaultValues,
        resolver: zodResolver(emailOtpCodeSchema),
    });
    const { control, handleSubmit, watch } = methods;

    const { identityVerificationId, setCurrentStep } = useGlobalContext();

    const handleVerifyEmailCallbackMutation = () => {
        setCurrentStep((prevState) =>
            prevState !== null ? prevState + NUMBER_TO_ADD_FOR_NEXT_STEP : null
        );
    };

    const { error, isLoading, verifyEmailCallbackMutation } = useVerifyEmailCallback(
        handleVerifyEmailCallbackMutation
    );

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const onSubmitHandler = async (values: EmailConfirmationFormValues) => {
        await verifyEmailCallbackMutation({
            identityId: typeof identityId === 'string' ? identityId : '',
            identityVerificationId: identityVerificationId || '',
            otpCode: values.otpCode,
        });
    };

    const isSubmitButtonDisabled = watch('otpCode').length !== OTP_CODE_LENGTH_6 || isLoading;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <PageWrapper>
                    <EmailConfirmationHeading providedEmail={email} />

                    <EmailConfirmationFormContent
                        control={control}
                        isSubmitButtonDisabled={isSubmitButtonDisabled}
                        isLoading={isLoading}
                    />
                </PageWrapper>
            </form>

            <ErrorAlertWrapper error={error} />
        </FormProvider>
    );
}
