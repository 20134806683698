import { Box, Button, CircularProgress, Typography } from '@mui/material';

import type { Control, FieldValues } from 'react-hook-form';

import { ControlledInput } from '../ControlledInput/ControlledInput';
import lang from '../lang/emailConfirmation';
import { ConfirmPhoneNumberFieldsEnum } from '../PhoneNumberConfirmation/constants/enums';

type ConfirmationStepFormType<FormFields extends FieldValues> = {
    control: Control<FormFields, unknown>;
    isSubmitButtonDisabled: boolean;
    resendCallback: () => Promise<void>;
    confirmationButtonText: string;
    isLoading: boolean;
    maxLength: number;
};

export function ConfirmationStepForm<FormFields extends FieldValues>({
    confirmationButtonText,
    control,
    isLoading,
    isSubmitButtonDisabled,
    maxLength,
    resendCallback,
}: ConfirmationStepFormType<FormFields>) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center',
                maxWidth: '400px',
                mt: 5,
                width: '100%',
            }}
        >
            <ControlledInput
                control={control}
                label={lang.VERIFICATION_CODE}
                name={ConfirmPhoneNumberFieldsEnum.OTP_CODE}
                isFullWidth
                maxLength={maxLength}
            />

            <Box
                sx={{
                    alignItems: 'center',
                    color: 'primary.main',
                    display: 'flex',
                    mb: 2,
                }}
            >
                <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                    {lang.RESEND_TEXT(maxLength)}
                    <Typography
                        component="span"
                        aria-label="resend"
                        variant="subtitle1"
                        onClick={resendCallback}
                        sx={{
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                        data-testid="resendButton"
                    >
                        {lang.RESEND}
                    </Typography>
                </Typography>
            </Box>

            <Button
                variant="contained"
                type="submit"
                sx={{ px: 10, py: 1.75 }}
                data-testid="confirmButton"
                disabled={isSubmitButtonDisabled}
                endIcon={
                    isLoading && (
                        <CircularProgress data-testid="loadingIcon" color="inherit" size="1.2rem" />
                    )
                }
            >
                {confirmationButtonText}
            </Button>
        </Box>
    );
}
