import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import type { z } from 'zod';

import { OTP_CODE_LENGTH_5 } from '@dock/common';
import { useLocalStorage } from '@dock/react-hooks';
import { emailOtpCodeSchema, phoneOtpCodeSchema } from '@dock/validation';

import { ErrorAlertWrapper } from '../../components/ErrorAlertWrapper/ErrorAlertWrapper';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { ConfirmPhoneNumberFieldsEnum } from '../../components/PhoneNumberConfirmation/constants/enums';
import { PhoneNumberConfirmationFormContent } from '../../components/PhoneNumberConfirmation/PhoneNumberConfirmationFormContent';
import { PhoneNumberConfirmationHeading } from '../../components/PhoneNumberConfirmation/PhoneNumberConfirmationHeading';
import { LocalStorageKeys } from '../../constants';
import { usePhoneNumberContext, useGlobalContext } from '../../hooks';
import { useVerifyPhoneNumberCallback } from '../../services';
import { formatPhoneNumberCountryCode } from '../../utils';

const NUMBER_TO_ADD_FOR_NEXT_STEP = 1;

export type PhoneConfirmationFields = z.infer<typeof emailOtpCodeSchema>;

const defaultValues: PhoneConfirmationFields = {
    [ConfirmPhoneNumberFieldsEnum.OTP_CODE]: '',
};

export function PhoneNumberConfirmation() {
    const { phoneNumber } = usePhoneNumberContext();

    const methods = useForm<PhoneConfirmationFields>({
        defaultValues,
        resolver: zodResolver(phoneOtpCodeSchema),
    });
    const { control, handleSubmit, watch } = methods;

    const { identityVerificationId, setCurrentStep } = useGlobalContext();

    const handleVerifyPhoneNumberCallbackMutation = () => {
        setCurrentStep((prevState) =>
            prevState !== null ? prevState + NUMBER_TO_ADD_FOR_NEXT_STEP : null
        );
    };

    const { error, isLoading, verifyPhoneNumberCallbackMutation } = useVerifyPhoneNumberCallback(
        handleVerifyPhoneNumberCallbackMutation
    );

    const { value: identityId } = useLocalStorage<string>(LocalStorageKeys.IDENTITY_ID);

    const onSubmitHandler = async (values: PhoneConfirmationFields) => {
        const formattedPhoneNumber = formatPhoneNumberCountryCode(phoneNumber);

        await verifyPhoneNumberCallbackMutation({
            code: values.otpCode,
            identityId: typeof identityId === 'string' ? identityId : '',
            identityVerificationId: identityVerificationId || '',
            mobileNumber: formattedPhoneNumber,
        });
    };

    const isSubmitButtonDisabled = watch('otpCode').length !== OTP_CODE_LENGTH_5 || isLoading;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <PageWrapper>
                    <PhoneNumberConfirmationHeading providedPhoneNumber={phoneNumber} />

                    <PhoneNumberConfirmationFormContent
                        control={control}
                        isSubmitButtonDisabled={isSubmitButtonDisabled}
                        isLoading={isLoading}
                    />
                </PageWrapper>
            </form>

            <ErrorAlertWrapper error={error} />
        </FormProvider>
    );
}
